import axios from 'axios';

/// although this is not working at the moment,
/// because we need to update infrastructure at AWS side too, to handle headers
/// But I want to keep these pieces here
axios.interceptors.request.use((config) => {
    // config.headers["Accept-Language"] = getLanguageCode();
    return config;
}, (error) => {
    console.log("Request interceptor error", error)
});

export default axios

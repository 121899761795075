import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'


import languagePT from './locales/Brazilian Portugese/translate.json'
import languageZH from './locales/Chinese - Simplified/translate.json'
import languageCS from './locales/Czech/translate.json'
import languageNL from './locales/Dutch/translate.json'
import languageFR from './locales/French/translate.json'
import languageDE from './locales/German/translate.json'
import languageHU from './locales/Hungarian/translate.json'
import languageIT from './locales/Italian/translate.json'
import languageJP from './locales/Japanese/translate.json'
import languageMY from './locales/Malay/translate.json'
import languagePL from './locales/Polish/translate.json'
import languageSK from './locales/Slovak/translate.json'
import languageES from './locales/Spanish/translate.json'
import languageSV from './locales/Swedish/translate.json'
import languageEN from './locales/English/translate.json'


i18n
.use(XHR)
.use(initReactI18next)
.init({
    resources: {
        en: languageEN,
        pt: languagePT,
        zh: languageZH,
        cs: languageCS,
        nl: languageNL,
        fr: languageFR,
        de: languageDE,
        hu: languageHU,
        it: languageIT,
        jp: languageJP,
        my: languageMY,
        pl: languagePL,
        sk: languageSK,
        es: languageES,
        sv: languageSV,
        mx: languageES
    },
    /* default language when load the website in browser */
    lng: localStorage.getItem('i18next'),
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
        useSuspense: true
    }
})

export default i18n;

const AppTheme = {
    palette: {
        primary: {
            main: '#008E5B', // "Kelly green",
            dark: '#1B4D4A', // "Prologis green"
            light: '#B7E59A'
        },
        secondary: {
            main: '#C4C4C4' // Light gray, form elements
        },
        error: {
            main: '#FE1818' // "Alerts and errors"
        },
        // warning: {},
        info: {
            main: '#1892BF', // Middle blue
            light: '#2CB5E5' // "Links and highlights" (light blue)
        },
        success: {
          main: '#71BE45' // "Light green highlight"
        },
        background: {
            paper: '#FFFFFF',
            default: '#EFEFEF',
        }
    },
    typography: {
        fontFamily: [
            'Source Sans Pro',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
        ].join(", ")
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
    },
}

export default AppTheme

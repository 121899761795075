import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppTheme from './themes/AppTheme';

import { TargetApp } from './globals/TargetApp'
import { UserInfo } from './globals/UserInfo'
import Routes from './components/Routes'
import { getAccessToken, getTargetApp, postAPI, getURLQueryParam, setLanguageCode } from './utils'

const theme = createTheme(AppTheme);

export default function App() {
    const getUserInfo = () => {
        return new Promise((resolve, reject) => {
            if (userProviderConfig.info !== null) {
                resolve(userProviderConfig.info)
            } else {
                if (userProviderConfig.request === null) {
                    console.log('initiating new user info request')

                    const req = postAPI('/getuser', {AccessToken: getAccessToken()})
                    .then((res) => {
                        setUserProviderConfig((config) => {
                            config.info = res.data
                            return {...config}
                        })
                        resolve(res.data)
                        return res
                    })
                    .catch((err) => {
                        reject(err)
                        throw err
                    })
                    .finally(() => {
                        setUserProviderConfig((config) => {
                            config.request = null
                            return {...config}
                        })
                    })

                    setUserProviderConfig((config) => {
                        config.request = req
                        return {...config}
                    })
                } else {
                    console.log('using existing user info request')

                    userProviderConfig.request
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                        throw err
                    })
                }
            }
        })
    }

    const getTargetAppConfig = () => {
        return new Promise((resolve, reject) => {
            if (appProviderConfig.config !== null) {
                resolve(appProviderConfig.config)
            } else {
                if (appProviderConfig.request === null) {
                    console.log('initiating new target app request')

                    const req = postAPI("/getapplicationconfiguration", {target_application: getTargetApp()})
                    .then((res) => {
                        setAppProviderConfig((config) => {
                            config.config = res.data
                            return {...config}
                        })
                        resolve(res.data)
                        return res
                    })
                    .catch((err) => {
                        reject(err)
                        throw err
                    })
                    .finally(() => {
                        setAppProviderConfig((config) => {
                            config.request = null
                            return {...config}
                        })
                    })

                    setAppProviderConfig((config) => {
                        config.request = req
                        return {...config}
                    })
                } else {
                    appProviderConfig.request
                    .then((res) => {
                        resolve(res.data)
                        return res
                    })
                    .catch((err) => {
                        reject(err)
                        throw err
                    })
                }
            }
        })
    }

    const [appProviderConfig, setAppProviderConfig] = React.useState({
        config: null,
        get: getTargetAppConfig,
        request: null
    })

    const [userProviderConfig, setUserProviderConfig ] = React.useState({
        info: null,
        get: getUserInfo,
        request: null
    })

    React.useEffect(() => {
        const languageCode = getURLQueryParam('language')
        if (languageCode) {
            setLanguageCode(languageCode)
            const url = new URL(window.location.href)
            const queryParams = new URLSearchParams(window.location.search)
            queryParams.delete('language')
            url.search = queryParams.toString()
            window.location.href = url.href
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <TargetApp.Provider value={appProviderConfig}>
                <UserInfo.Provider value={userProviderConfig}>
                    <Routes />
                </UserInfo.Provider>
            </TargetApp.Provider>
        </ThemeProvider>
    );
}
